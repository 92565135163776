<template>
  <div
    ref="stBaseSelect"
    class="st-base-select"
    :class="{ disabled: isDisabled }"
    data-t="st-base-select"
  >
    <slot name="field" :is-expanded="isExpanded"></slot>
    <div class="separator"></div>

    <div class="base-dropdown">
      <StDrawer v-if="isMobile" v-model="isExpanded" :full-screen="fullScreen">
        <StDrawerHeader
          :title="mobileTitle"
          disable-router-back
          @close="isExpanded = false"
        />
        <slot name="top"></slot>
        <div class="options-wrapper mobile-drawer" data-t="mobile-drawer-o38w">
          <slot> </slot>
        </div>
      </StDrawer>
      <StTransitionExpand v-else>
        <div v-if="isExpanded" class="expanded" data-t="expanded">
          <div class="top-block">
            <slot name="top"></slot>
          </div>
          <div class="options-wrapper" data-t="options-content-sm5q">
            <slot> </slot>
          </div>
        </div>
      </StTransitionExpand>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@st/use/composables'
import type { BaseSelectApi } from './types'

const props = withDefaults(
  defineProps<{
    modelValue?: string | number
    isDisabled?: boolean
    mobileTitle?: string
    clickOutsideDisabled?: boolean
    dropdownMaxHeight?: string | number
    fullScreen?: boolean
    topOffset?: string
  }>(),
  {
    isDisabled: false,
    fullScreen: false,
    clickOutsideDisabled: false,
  },
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: number | string): void
  (e: 'onSelectItem', value: any): void
}>()

const { isMobile } = usePlatform()

const isExpanded = ref(false)

const stBaseSelect = ref<HTMLDivElement>()
onClickOutside(stBaseSelect, () => {
  if (props.clickOutsideDisabled || isMobile.value) return

  isExpanded.value = false
})

function selectItemHandler(id: string | number) {
  isExpanded.value = false

  emit('update:modelValue', id)
}

function toggleDropdown() {
  if (props.isDisabled) return

  isExpanded.value = !isExpanded.value
}

const api: BaseSelectApi = {
  selectItemHandler,
  toggleDropdown,
  isExpanded: computed(() => isExpanded.value),
}

provide('StBaseSelectApi', api)

const maxHeight = computed(() => `${props.dropdownMaxHeight}px`)
const topPosition = computed(() =>
  props.topOffset ? `${props.topOffset}px` : 'unset',
)
</script>

<style scoped>
.st-base-select {
  position: relative;
  width: 100%;

  &.disabled {
    pointer-events: none;
  }
}

.base-dropdown {
  position: absolute;
  z-index: 999;
  top: v-bind(topPosition);

  overflow: auto;

  width: max-content;
  min-width: 100%;
  margin-top: var(--spacing-100);

  background-color: var(--background-secondary);
  border-radius: var(--border-radius-100);
  box-shadow:
    0 8px 12px -4px rgb(0 0 0 / 32%),
    0 16px 24px -4px rgb(0 0 0 / 24%);
}

.expanded {
  overflow: auto;
  height: v-bind(maxHeight);
}

.top-block {
  position: sticky;
  z-index: 20;
  top: 0;
}

.options-wrapper {
  padding: var(--spacing-050);
}

.mobile-drawer {
  overflow: auto;
  height: 100%;
  padding-bottom: var(--spacing-250);

  .options-wrapper {
    height: 100%;
  }
}
</style>
