<template>
  <div class="currency-select" data-t="currency-select">
    <StBaseSelect
      v-model="selectedCurrencyId"
      :has-drawer="platform === 'mobile'"
      :mobile-title="t('payments.deposit.chooseCurrency')"
      dropdown-max-height="350"
    >
      <template #field="data">
        <PaymentsSelectField
          :placeholder="t('payments.deposit.chooseCurrency')"
          :label="t('payments.deposit.currency')"
          :icon-prefix="selectedItem?.icon"
          :icon-postfix="iconPostfix(data)"
          :first-value="selectedItem?.title"
          :second-value="selectedItem?.subtitle"
          :third-value="selectedItem?.value"
          :platform="platform"
        />
      </template>
      <div class="options-title" :class="platform">
        <div class="title left">
          {{ t('payments.deposit.currency') }}
        </div>
        <div class="title right">
          {{ t('payments.deposit.myBalance') }}
        </div>
      </div>
      <PaymentsSelectOption
        v-for="account in mappedAccounts"
        :id="account.id"
        :key="account.id"
        :item="account"
        :platform="platform"
        :is-selected="selectedItem?.id === account.id"
      />
      <SiteAvailableCrypto class="currencies-banner" background="primary" />
    </StBaseSelect>
  </div>
</template>

<script setup lang="ts">
import { isNullish } from '@st/utils'
import type { UserAccount } from '../../interfaces'
import type { ExtendedUserAccount } from '../../composables/useAllowedAssets'
import { useAccountsStore } from '../../stores/useAccountsStore'
import { useCurrenciesStore } from '../../stores/useCurrenciesStore'
import PaymentsSelectField from '../PaymentsSelectField/PaymentsSelectField.vue'
import PaymentsSelectOption from '../PaymentsSelectOption/PaymentsSelectOption.vue'

const {
  // @TODO: по возможности заменить на использование usePlatform()
  platform = 'desktop',
  operationType = 'address',
  allowedIds,
  isDisabled,
} = defineProps<{
  platform: 'mobile' | 'desktop'
  operationType?: 'deposit' | 'withdrawal' | 'address'
  allowedIds?: number[]
  isDisabled?: boolean
}>()

const selectedCurrencyId = defineModel<number | undefined>({ required: true })

const currenciesStore = useCurrenciesStore()
const { appCurrency } = storeToRefs(currenciesStore)

const { format: formatCurrency } = useCurrencyFormatter({
  currency: appCurrency.value.code,
})
const { format: formatCrypto } = useCryptoFormatter()
const { t } = useI18n()

const { realAccounts: sRealAccounts } = storeToRefs(useAccountsStore())
const { realAccounts: cRealAccounts } = useAllowedAssets({
  selectedCurrencyId,
  omitZeroBalanceFiltration: true,
})

const usedAccounts = computed<(UserAccount | ExtendedUserAccount)[]>(() =>
  operationType === 'address' ? sRealAccounts.value : cRealAccounts.value,
)

function isEnabledCurrency(item: (typeof usedAccounts.value)[0]) {
  const { allowedForDeposit, allowedForWithdrawal } = item
  let { isAvailable } = item as ExtendedUserAccount
  isAvailable = isNullish(isAvailable) ? true : isAvailable
  switch (operationType) {
    case 'deposit': {
      return allowedForDeposit && isAvailable
    }

    case 'withdrawal': {
      return allowedForWithdrawal && isAvailable
    }

    case 'address': {
      return false
    }

    default: {
      return isAvailable
    }
  }
}

const iconPostfix = computed(() => (data: { isExpanded: boolean }) => {
  if (isDisabled) return 'lock-solid'

  return data.isExpanded ? 'chevron-top' : 'chevron-down'
})

const mappedAccounts = computed(() =>
  usedAccounts.value
    .filter((item) => !allowedIds || allowedIds.includes(item.currencyId))
    .map((item) => ({
      id: item.currencyId,
      title: item.code,
      subtitle: item.name,
      icon: item.icon,
      disabled: !isEnabledCurrency(item),
      value: formatCrypto(item.balance),
      subValue: `~${formatCurrency(item.fiatBalance)}`,
    })),
)

const selectedItem = computed(() =>
  mappedAccounts.value.find(
    (account) => account.id === selectedCurrencyId.value,
  ),
)
</script>

<style scoped>
.currency-select {
  display: flex;
}

.options-title {
  display: flex;
  gap: var(--spacing-150);
  justify-content: space-between;

  margin-bottom: var(--spacing-025);
  padding: var(--spacing-050) var(--spacing-200);

  font: var(--desktop-text-xs-medium);
  color: var(--palette-light-600);

  border-bottom: 1px solid var(--border-secondary);

  .title {
    padding: var(--spacing-100) 0;
  }

  .right {
    margin-right: var(--spacing-400);
  }
}

.options-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-100) var(--spacing-100) var(--spacing-100)
    var(--spacing-150);
}

.header-text {
  font: var(--mobile-title-2-semibold);
}

.options-title.mobile {
  padding: var(--spacing-050) var(--spacing-200);
  border-bottom: none;

  .title {
    padding: 0;
  }

  .right {
    margin-right: var(--spacing-300);
  }
}

.currencies-banner {
  margin-top: var(--spacing-200);
}
</style>
